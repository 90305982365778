import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { WithStyles } from '@material-ui/core'
// import { Popover } from "@material-ui/Popover"
import { removeStorageData } from "../../framework/src/Utilities";
import { getNavigationMessage } from "./Utils";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import { emptyString } from "react-select/src/utils";

interface ResponseMessage {
    message: string;
}
// Customizable Area End

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    isLogin?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: any;
    // isUserActive:string;
    open: any;
    options: any;
    selectedIndex: any;
    id: any;
    isActive: boolean,
    drawerStatus: boolean;
    isLogin: any,
    isBusinessOwner: any,
    logInToken: any
    isSubscriber: string,
    isProfileCreated: string,
    profileHeaderIcon: boolean,
    showLogout: boolean,
    logoutMenu: boolean,
    isCustomerUserProfilePage: boolean;
    cartItemCount: number;
    isLanguageModalOpen: boolean;
    selectedCountry: any;
    businessToggle: boolean;
    showProfile: boolean;
    selectedLanguage: {
        name: string,
        identifier: string,
        direction: string,
    };
    customerLogin: boolean;
    categoryArr: Array<{
        name: string,
        path: string,
        backgroundColor: string,
        logo: string,
        color: string,
    }>;
    userProfilePhoto: any
    isMyQrDialogOpen: boolean;
    loading: boolean;
    userBusinessDetails: {
        business_name: string,
        photo?: string,
        qr_pdf_url?: string,
        qr_code_url: string
    };
    userSubscriberQrDetails: {
        qr_code_url: string,
        qr_pdf_url?: string,
    };
    userName: string;
    email: string,
    businessUserPermissions: { [key: string]: any },
    branchList: any[];
    selectedBranch: any,
    selectedBranchId: string;
    openInvalidTokenDialog: boolean;
    businessOwner: boolean;
    isPublicUrl: boolean,
    sendEmail:boolean,
    openAddToAppleWalletDialog: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export const configJSON = require("./config");

export default class HeaderController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getBusinessDetailsApiId: any
    getUserProfileDetailsApiId: any
    getSubscriberQrApiId: any
    branchListApiId: string
    sendEmailApiId: string;
    sendQrToEmailApiId:string
    // Customizable Area End    

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseToken),
            getName(MessageEnum.SelectedBranch),
        ];

        this.state = {
            drawerStatus: false,
            // isUserActive:localStorage.getItem('isUserActive') || 'false',
            cartItemCount: 0,
            isLanguageModalOpen: false,
            isCustomerUserProfilePage: false,
            selectedCountry: {},
            selectedLanguage: {
                name: 'English',
                identifier: 'en',
                direction: 'ltr'
            },
            customerLogin: false,
            categoryArr: [],
            options: ["profile", "setting", "Logout"],
            anchorEl: null,
            selectedIndex: 1,
            id: "",
            open: false,
            isActive: false,
            profileHeaderIcon: false,
            logoutMenu: false,
            showLogout: false,
            isLogin: false,
            isBusinessOwner: false,
            logInToken: null,
            businessToggle: false,
            showProfile: false,
            isProfileCreated: localStorage.getItem('isProfileCreated') || "false",
            isSubscriber: localStorage.getItem('isSubscriber') || "false",
            userProfilePhoto: localStorage.getItem('userProfilePhoto') || null,
            isMyQrDialogOpen: false,
            loading: false,
            userBusinessDetails: {
                business_name: "",
                photo: "",
                qr_pdf_url: "",
                qr_code_url: ""
            },
            userSubscriberQrDetails: {
                qr_code_url: "",
                qr_pdf_url: "",
            },
            userName: "",
            email: "",
            businessUserPermissions: {},
            branchList: [],
            selectedBranch: "",
            selectedBranchId: "",
            openInvalidTokenDialog: false,
            businessOwner: false,
            isPublicUrl: false,
            sendEmail:false,
            openAddToAppleWalletDialog: false
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        const selectedLanguage = await getStorageData('selectedLanguageDetail', true);
        const selectedCountry = await getStorageData('selectedCountry', true);
        const category = await getStorageData('category', true);
        this.setState({ categoryArr: category })
        const token = await getStorageData('token', false);
        const logInRes = await getStorageData('logInRes', true);
        const otpConfRes = await getStorageData('otpConfRes', true);
        this.stateSetAfterSignup(otpConfRes);
        this.saveLogInResAfterLogIn(logInRes);
        this.checkPathName()



        //@ts-ignore
        if (this.props.children[0]?._owner.key === '/CustomisableUserProfiles' && this.state.isProfileCreated === 'false') {
            this.setState({ profileHeaderIcon: true })
        }
        if (token) {
            this.setState({ customerLogin: true })
        }



        if (!selectedLanguage || !selectedCountry) {
            this.setState({
                isLanguageModalOpen: true
            })
        }
        else {
            this.setState({
                selectedLanguage,
                selectedCountry
            })
        }
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.branchListApiId) {
                this.branchListApiRes(responseJson);
            }
            if (apiRequestCallId === this.getBusinessDetailsApiId) {
                this.handleBusinessDetailsApiRes(responseJson);
            }
            if (apiRequestCallId === this.getUserProfileDetailsApiId) {
                this.handleUserDetailsApiRes(responseJson);
            }
            if (apiRequestCallId === this.getSubscriberQrApiId) {
                this.handleSubscriberQrApiRes(responseJson);
            }
            if (apiRequestCallId === this.sendEmailApiId) {
                this.handleChangePasswordRes(responseJson);
            }
            if(apiRequestCallId === this.sendQrToEmailApiId){
                this.handleSendEmailRes(responseJson)
            }
            runEngine.debugLog("Message Recived", message);
        }
        if (getName(MessageEnum.SelectedBranch) === message.id) {
            const branchDetails = message.getData(getName(MessageEnum.SelectedBranchData));
            this.setState({ selectedBranchId: branchDetails[0].id, businessUserPermissions: branchDetails[1] })
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleDrawerOpen = () => {
        this.setState({ drawerStatus: true })

    };

    saveLogInResAfterLogIn = async (logInRes: any) => {
        let isLogin = await getStorageData("isLogin", true);
        let isBusinessOwners = await getStorageData("BusinessOwner", true);
        if (logInRes) {
            this.setState({
                isLogin: isLogin,
                logInToken: logInRes.logInToken,
                isBusinessOwner: isBusinessOwners
            });
            this.branchListApiCall(logInRes.logInToken);
            this.businessDetailsApiCall(logInRes.logInToken);
            this.getUserProfileDetailsApiCall(logInRes.logInToken)
        }
    }

    stateSetAfterSignup = (otpConfRes: any) => {
        if (otpConfRes) {
            this.setState({ logInToken: otpConfRes?.token });
            this.branchListApiCall(otpConfRes?.token);
            this.businessDetailsApiCall(otpConfRes?.token);
            this.getUserProfileDetailsApiCall(otpConfRes?.token)
        }
    }

    onClick = () => {
        const { isActive } = this.state;
        this.setState({ isActive: !isActive });
    };

    handleDrawerClose = () => {
        this.setState({ drawerStatus: false })
    };

    handleShowLogout = (event: any) => {
        this.setState({ showLogout: true }),
            this.setState({ logoutMenu: event.currentTarget })
    }

    onCartClick = () => {
        // todo
    }
    openLanguageSelectionModal = () => {
        this.setState({ isLanguageModalOpen: true })
    }
    handleUser = () => {
        (async () => {
            const token = await getStorageData('token');
            if (token) {
                this.props.navigation.navigate('Home')
            } else {
                this.props.navigation.navigate('Login')
            }
        })();
    }
    handleUserlogOut = async () => {
        await removeStorageData('token');
        this.props.navigation.navigate('Login')
    }

    handleMyQrBtn = async () => {

        const logInResp = await getStorageData('logInRes', true);
        const otpConfResp = await getStorageData('otpConfRes', true);

        if (!logInResp && !otpConfResp) {
            window.localStorage.clear();
            this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
        }
        const { isSubscriber } = this.state;

        if (isSubscriber === "true") {
            this.subscriberQrApiCall();
        } else {
            this.setState({ isMyQrDialogOpen: true });
        }
    }

    handleQrDialogClose = () => {
        this.setState({ isMyQrDialogOpen: false });
    }

    handleDownloadQrBtn = () => {
        const { isSubscriber } = this.state
        if (isSubscriber === "true") {
            window.open(this.state.userSubscriberQrDetails?.qr_pdf_url);
        } else {
            window.open(this.state.userBusinessDetails?.qr_pdf_url);
        }
    }

    getLogInToken = () => {
        let token;
        const otpRes = localStorage.getItem('otpConfRes');
        const loginRes = localStorage.getItem('logInRes');
        if (otpRes) {
            const otpResParse = JSON.parse(otpRes);
            token = otpResParse.token;
            return token;
        }
        if (loginRes) {
            const otpResParse = JSON.parse(loginRes);
            token = otpResParse.logInToken;
            return token;
        }
    }

    businessDetailsApiCall = (token: any) => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": "application/json",
            token: token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getBusinessDetailsApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBusinessDetailsApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    sendQrToEmail = () => {
        const header = {
            "Content-Type": "application/json",
            token: this.getLogInToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.sendQrToEmailApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_custom_user_subs/send_qr_email`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSendEmailRes = (response:ResponseMessage)=>{
        if(response.message){
            this.setState({sendEmail:true})
        }

    }
    closeDialogs = ()=>{
        this.setState({isMyQrDialogOpen:false,sendEmail:false})
    }


    subscriberQrApiCall = () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": "application/json",
            token: this.getLogInToken()
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getSubscriberQrApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSubscriberQrApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleBusinessDetailsApiRes = (responseJson: any) => {
        this.setState({ loading: false });
        localStorage.setItem('BusinessId',responseJson.data.id)
        if (!responseJson.error) {
            const { business_name, photo, qr_code_url, qr_pdf_url } = responseJson.data?.attributes;
            this.setState({
                userBusinessDetails: {
                    ...this.state.userBusinessDetails,
                    business_name,
                    photo,
                    qr_code_url,
                    qr_pdf_url
                },
            });
        }
    }

    handleBranchChange = (event: React.ChangeEvent<{ value: unknown }>) => {

        const selected_branchDetails = this.state.branchList.find((branchArray: any[]) => branchArray.some(branch => branch.id == event.target.value));
        const msg: Message = new Message(getName(MessageEnum.SelectedBranch));
        msg.addData(getName(MessageEnum.SelectedBranchData), selected_branchDetails);
        this.send(msg);
    }

    getUserProfileDetailsApiCall = (token: any) => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": "application/json",
            token: token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.getUserProfileDetailsApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getUserProfileDetailsApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    branchListApiCall = (token: any) => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": "application/json",
            token: token
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.branchListApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.branchListApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    branchListApiRes = async (responseJson: any) => {
        let selected_branchId = this.state.selectedBranchId;
        this.setState({ loading: false });
        if (!responseJson.error) {
            const { branch } = responseJson;
            const selected_branchDetails = branch[0];
            if (!selected_branchId) {
                const msg: Message = new Message(getName(MessageEnum.SelectedBranch));
                msg.addData(getName(MessageEnum.SelectedBranchData), selected_branchDetails);
                this.send(msg);
            }

            this.setState({branchList: branch});
        }
    }

    handleUserDetailsApiRes = (responseJson: any) => {
        let userName;
        this.setState({ loading: false });
        if (!responseJson.errors) {
            const { photo, first_name, last_name, email, account } = responseJson.data?.attributes;
            userName = `${first_name} ${last_name}`
            this.setState({ userProfilePhoto: photo, userName: userName, email: email, businessOwner: account.business_owner });
            localStorage.setItem('BusinessOwner',responseJson.data?.attributes.account.business_owner)
        } else {
            const {errors} = responseJson;
            if(errors[0]?.token === "Invalid token"){
                this.setState({openInvalidTokenDialog: true})
            }
        }
    }

    handleSubscriberQrApiRes = (responseJson: any) => {
        this.setState({ loading: false });
        if (!responseJson.error) {
            const { qr_code_url, qr_pdf_url } = responseJson;
            this.setState({
                userSubscriberQrDetails: {
                    ...this.state.userSubscriberQrDetails,
                    qr_code_url,
                    qr_pdf_url
                }
            });
            this.setState({ isMyQrDialogOpen: true });
        }
    }

    updateBranchList = () => {
        this.branchListApiCall(this.state.logInToken);
    }

    handleInvalidTokenDialogBtn = () => {
        this.setState({openInvalidTokenDialog: false});
        window.localStorage.clear();
        this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
    }

    // Customizable Area End

    handleImageClick = (e: any) => {
        this.setState({ anchorEl: e.currentTarget })
    }


    handleProfileAccountIcon = async () => {
        setStorageData("token", '');
        const token = await getStorageData('token');
        if (token === null) {
            this.props.navigation.navigate('EmailAccountLoginBlock')
        }

    }

    handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        this.setState({ selectedIndex: index });
        // setAnchorEl(null);
        this.setState({ anchorEl: null })
    };

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    handleClickListItem = (e: any) => {
        // setAnchorEl(event.currentTarget);
        this.setState({ anchorEl: e.currentTarget })
    }

    handleClick = (event: any) => {
        this.setState({ anchorEl: event.currentTarget, open: !this.state.open });
    }
    customerUserProfileSignup = () => {
        const { pathname } = window.location;
        if (pathname === "/customerUserProfile") {
            this.setState({ isCustomerUserProfilePage: true });
        }
    }

    onLogout = () => {
        this.context.set({
            loginData: null,
            isLogin: false,
            isSignup: false,
            isSubscriber: null,
            signUpToken: null,
            logInToken: null,
            email: null,
            isProfileCreated: false,
            isRegister: false
        });
        localStorage.removeItem('isRegister')
        this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
        window.localStorage.clear();
        this.removeSessionToken();
    }

    removeSessionToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
        msg.addData(
            getName(MessageEnum.SessionResponseToken),
            null
        );

        this.send(msg);
    }

    goToSubscribersList = () => {
        this.send(getNavigationMessage("SubscribersList", this.props));
    }

    handleToggleDropdown = () => {
        this.setState((prevState) => ({
            businessToggle: !prevState.businessToggle
        }));
    };

    handleLogoClick = async () => {
        const { isLogin, isProfileCreated } = this.state;
        let isBusinessOwners =  await getStorageData("BusinessOwner", true);
        let isAuthToken =  await getStorageData("authToken");
        let isRegister =  await getStorageData("isRegister" , false);
        if (isBusinessOwners) {
        }
        else if (!isAuthToken  && !isRegister) {
            window.location.href = 'https://www.go-qr.co.uk/';
        }
        else if (isLogin || isProfileCreated == "true") {
            this.send(getNavigationMessage("LandingPage", this.props));
        }
        else {
            window.localStorage.clear();
            this.send(getNavigationMessage("EmailAccountLoginBlock", this.props));
        }
    }

    checkPathName = () => {
        const pathName = window.location.pathname.slice(1);
        if (pathName === 'BusinessSubscriptions') {
            this.setState({ isPublicUrl: true })
        } else {
            this.setState({ isPublicUrl: false })
        }
    }

    handleChangePassword = () => {
        this.setState({loading: true});

        const headers = {
            "Content-Type": "application/json",
            // token: localStorage.getItem("authToken"),
        };

        const attrs = {
            email: this.state.email,
        };

        const data = {
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendEmailApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_forgot_password/otps"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleChangePasswordRes = (responseJson: any) => {
        this.setState({loading: false});
        if(responseJson.meta){
            const {meta} = responseJson;
            setStorageData('forgotToken', meta.token);
            this.send(getNavigationMessage("ChangeNewPassword", this.props));
        }
    }

}